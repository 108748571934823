import {
  rolesById,
  type KnapsackNavItem,
  type KsAppClientData,
  type Role,
} from '@knapsack/types';
import { getTypeFromPathMemo } from '@/utils/routes';
import { useIsSitePrivate } from '@/core';

export function getNavItemInfo({
  navItem,
  appClientData: { patternsState, customPagesState },
}: {
  appClientData: KsAppClientData;
  navItem: Pick<KnapsackNavItem, 'path' | 'name'>;
}) {
  const { contentType, contentId } = getTypeFromPathMemo(navItem.path);
  let title = navItem.name || navItem.path;
  switch (contentType) {
    case 'pattern': {
      title = patternsState.patterns?.[contentId]?.title;
      break;
    }
    case 'page': {
      title = customPagesState.pages?.[contentId]?.title;
      break;
    }
  }
  return { title, contentType, contentId };
}

export function useUserCanAccessItem({
  userRole,
  minRoleNeeded,
}: {
  userRole: Role;
  minRoleNeeded: Role;
}) {
  const isSitePrivate = useIsSitePrivate();
  const fallbackRole: Role = isSitePrivate ? 'VIEWER' : 'ANONYMOUS';
  const userRoleHierarchy = rolesById[userRole].hierarchy;
  const minRoleHierarchy = rolesById[minRoleNeeded || fallbackRole].hierarchy;

  return userRoleHierarchy >= minRoleHierarchy;
}
